
/* Change this file to get your personal Portfolio */

// Your Summary And Greeting Section

import emoji from "react-easy-emoji";

const greeting = {
  /* Your Summary And Greeting Section */
  username: "Nethelbert Blasse",
  title: "Hi all, I'm Nethelbert",
  subTitle: emoji("A passionate Software Developer 🚀 having an experience of building Web applications with JavaScript / Reactjs / Nodejs / PHP and some other cool libraries and frameworks."),
  resumeLink: "https://docs.google.com/document/d/1jJr44BZO9-jK17hBWsoqtrt4GDUocvF24VD-GuAToOQ/edit"
};

// Your Social Media Link
const socialMediaLinks = {
  github: "https://github.com/gilblasse",
  linkedin: "https://linkedin.com/in/nethelbert-blasse/",
  gmail: "nethelbert.blasse@gmail.com",
  medium: "https://medium.com/@nethelbert.blasse",
  // Instagram and Twitter are also supported in the links!
};

// Your Skills Section

const skillsSection = {
  title: "What i do",
  subTitle: "CRAZY FULL STACK DEVELOPER WHO WANTS TO EXPLORE EVERY TECH STACK",
  skills: [
    emoji("⚡ Develop highly interactive Front end / User Interfaces for your web applications"),
    // emoji("⚡ Progressive Web Applications ( PWA ) in normal and SPA Stacks"),
    emoji("⚡ Integration of third party services such as Firebase/ AWS")
  ],

/* Make Sure You include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "HTML-5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "Css3",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "Sass",
      fontAwesomeClassname: "fab fa-sass"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "React Js",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "Node Js",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "Ruby On Rails",
      fontAwesomeClassname: "fa fa-gem"
    },
    {
      skillName: "Laravel Blade",
      fontAwesomeClassname: "fab fa-laravel"
    },
    {
      skillName: "Python",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "NPM",
      fontAwesomeClassname: "fab fa-npm"
    },
    {
      skillName: "PostgreSQL",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "AWS",
      fontAwesomeClassname: "fab fa-aws"
    },
    {
      skillName: "Firebase",
      fontAwesomeClassname: "fas fa-fire"
    },
    {
      skillName: "Docker",
      fontAwesomeClassname: "fab fa-docker"
    }
  ]
};

// Your education background

const educationInfo = {
  viewEducation: true, // Set it to true to see education section
  schools: [
    {
      schoolName: "Flatiron School",
      logo: require("./assets/images/Flatiron-Logo.png"),
      subHeader: "Software Engineering",
      duration: "November 2019 - April 2020",
      desc: "Technologies Learned & Used: Ruby (On Rails), JavaScript, REACT, Redux, React Hooks, MVC Structure, REST API, JSON, SQL, SQLite, PostgreSQL, Heroku, JWT, BCRYPT",
      descBullets: [
        "Implemented MVC structure when using Ruby on Rails with RESTular routes.",
        "Utilized RESTful APIs.",
        "Improved JavaScript website applications by using REACT.js and Redux.",
        "Designed frontend with frameworks, such as Bootstrap, Material UI, Semantic UI, and Advance CSS.",
      ],
    },
    {
      schoolName: "Aviation Institute Of Maintenance",
      logo: require("./assets/images/AIM-Logo-Horizontal.png"),
      subHeader: "Associates of Science in Aviation Maintenance Technology",
      duration: "September 2010 - February 2012",
      desc: "Graduated Summa Cum laude",
      // descBullets: [
      //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      // ],
      style: {objectFit: "contain"}
    }
  ]
}

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Frontend/Design",  //Insert stack or technology you have experience in
      progressPercentage: "85%"  //Insert relative proficiency in percentage
    },
    {
      Stack: "Backend",
      progressPercentage: "70%"
    },
    {
      Stack: "Database",
      progressPercentage: "70%"
    }
  ]
};


// Your top 3 work experiences

const workExperiences = {
  viewExperiences: true,
  experience: [
    {
      role: "FullStack Engineer",
      company: "Private Wealth Systems",
      companylogo: require("./assets/images/cropped-Icon_Black_Background2-32x32.png"),
      date: "April 2021 – Present",
      desc: "As a key developer at Private Wealth Systems, I led the modernization of a legacy Angular application to React TypeScript, enhancing its performance and user interface. I crafted robust REST endpoints using C# .NET, streamlined deployment with a Jenkins and Docker CI/CD pipeline, and excelled in front-end development and state management using Redux and Saga.",
      // descBullets: [
      //   "Migrate legacy Angular to React application.",
      //   "Build REST endpoints in C# .NET framework.",
      //   "Implemented CI/CD pipeline using Jenkins and Docker.",
      //   "Implement Redux and Saga to manage state effectively across all components in SPA architecture.",
      //   "Served as the main point of contact regarding our application’s front-end and supporting technologies.",
      // ]
    },
    {
      role: "Software Developer",
      company: "Curious Minds",
      companylogo: require("./assets/images/media_licdn_com-1604527331774.png"),
      date: "Dec 2020 – Mar 2021",
      desc: "At Curious Minds, I specialized in delivering comprehensive web solutions with a focus on cross-browser compatibility and client-specific custom PHP templates. My collaborative approach significantly improved project management efficiency and product reliability, while also addressing and solving system deficiencies.",
      // descBullets: [
      //   "Constructed custom PHP templates according to client needs.",
      //   "Identified system deficiencies and developed effective solutions.",
      //   "Participated in daily collaboration with other members on production teams regarding various assigned projects.",
      // ]
    },
    {
      role: "React Developer",
      company: "Freelance",
      companylogo: require("./assets/images/userLaptop.png"),
      date: "Aug 2020 – Dec 2020",
      desc: "In my freelance role, I developed a Sou-Sou application for a YouTuber, utilizing React, Redux, and Ruby on Rails. My work emphasized creating an intuitive UI/UX and maintaining application stability through test-driven development, showcasing my ability to produce user-centric and reliable software solutions.",
      // descBullets: [
      //   "Developed a Sou-Sou application for a YouTuber using React, Redux, and Ruby on Rails backend.",
      //   "Implemented a custom UI/UX design to create a seamless user experience.",
      //   "Utilized a test-driven development methodology to ensure application stability and reliability.",
      // ]
    },
    {
      role: "Junior Developer",
      company: "Amazing Grace Transport",
      companylogo: require("./assets/images/AmazingGraceLogo.png"),
      date: "January 2019 – Dec 2020",
      desc: "My tenure as a Junior Web Developer involved enhancing CMS functionality and developing a React, Ruby on Rails, and PostgreSQL-based dispatching and time management system. This system improved operational efficiency and included a custom reporting feature, significantly boosting user experience and data analysis capabilities.",
      // descBullets: [
      //   "Maintained CMS  including installation, configuration, troubleshooting, and creation of content post types.",
      //   "Managed technical integration with third-party services.",
      //   "Developed a dispatching & time management system using React, Ruby on Rails and PostgreSQL",
      //   "Created a feature that allowed users to generate custom reports and analyze data.",
      // ]
    },
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  githubConvertedToken: process.env.REACT_APP_GITHUB_TOKEN,
  githubUserName: "gilblasse", // Change to your github username to view your profile in Contact Section.
  showGithubProfile :"true" // Set true or false to show Contact profile using Github, defaults to false
};


// Some Big Projects You have worked with your company

const bigProjects = {
  // title: "Big Projects",
  // subtitle: "SOME STARTUPS AND COMPANIES THAT I HELPED TO CREATE THEIR TECH",
  // projects: [
  //   {
  //     image: require("./assets/images/saayaHealthLogo.webp"),
  //     link: "http://saayahealth.com/"
  //   },
  //   {
  //     image: require("./assets/images/nextuLogo.webp"),
  //     link: "http://nextu.se/"
  //   }
  // ]
};

// Your Achievement Section Include Your Certification Talks and More 

const achievementSection = {

  title: emoji("Technical Projects 🏆"),
  subtitle: "Here are some cool Technical Projects I have worked on !",

  achievementsCards: [

    {
      title: "Hale Advisors",
      url: "https://adoring-mcclintock-22a297.netlify.app",
      gitUrl: "https://github.com/Gilblasse/HAmarket",
      subtitle: "Hale Advisors is a local company that focuses on marketing and communications.  'Password to enter site: hale2021' ",
      image: require("./assets/images/Hale-Advisors-screenshot.png"),
      footerLink: ["Laravel Jigsaw", "Tailwind", "AWS S3"]       
    },

    {
      title: "Walkway Nail Spa",
      url: "https://laughing-sinoussi-cf4df5.netlify.app",
      gitUrl: "https://github.com/Gilblasse/walkway-nail-spa__poughkeepsie_ny",
      subtitle: "I'm constantly improving this Website for a client.",
      image: require("./assets/images/walkway nail spa.png"),
      footerLink: ["Material UI", "React", "Nylas", "MapBox GL", "Framer Motion", "Recoil"]
    },

    {
      title: "COVID 19 Tracker",
      url: "https://covid-tracker-88d1a.firebaseapp.com/",
      gitUrl: "https://github.com/Gilblasse/COVID-19-Tracker",
      subtitle: "Application tracks Coronavirus COVID-19 global Cases",
      image: require("./assets/images/COVID19-Chart.png"),
      footerLink: ["Firebase", "Chart Js", "React", "Leaflet"]
      
      // [
      //   { name: "Firbase", url: "https://drive.google.com/file/d/0B7kazrtMwm5dYkVvNjdNWjNybWJrbndFSHpNY2NFV1p4YmU0/view?usp=sharing" },
      //   { name: "Award Letter", url: "https://drive.google.com/file/d/0B7kazrtMwm5dekxBTW5hQkg2WXUyR3QzQmR0VERiLXlGRVdF/view?usp=sharing" },
      //   { name: "Google Code-in Blog", url: "https://opensource.googleblog.com/2019/01/google-code-in-2018-winners.html" }
      // ]
    },
    

  ]
};

// Blogs Section

const blogSection = {

  title: "Blogs",
  subtitle: "With Love for Developing cool stuff, I love to write and teach others what I have learnt.",

  blogs: [
    {
      url: "https://medium.com/@nethelbert.blasse/rails-art-of-abstraction-1d2cc010e981",
      title: "Rails Art of Abstraction",
      description: "Rails build’s on the idea of simplicity. This concept allows for dynamic websites, clean code, and reusable functionality."
    },
    {
      url: "https://medium.com/@nethelbert.blasse/conditional-css-in-react-f47084e44f35",
      title: "Conditional CSS In React",
      description: "In this blog, we will explore the many ways to conditionally create classes for our components."
    }
  ]
};

// Talks Sections

const talkSection = {
  // title: "TALKS",
  // subtitle: emoji("I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅"),

  talks: [
    // {
    //   title: "Build Actions For Google Assistant",
    //   subtitle: "Codelab at GDG DevFest Karachi 2019",
    //   slides_url: "https://bit.ly/my-slides",
    //   event_url: "https://www.facebook.com/events/2339906106275053/"
    // }
  ]
};

// Podcast Section

const podcastSection = {
  // title: emoji("Podcast 🎙️"),
  // subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    // "https://anchor.fm/codevcast/embed/episodes/DevStory----mypodcast--Pakistan-e9givv/a-a15itvo"
  ]
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle: "Discuss a project or just want to say hi? My Inbox is open for all.",
  number: "+1-845-891-8994",
  email_address: "nethelbert.blasse@gmail.com"
};

//Twitter Section

const twitterDetails = {

  // userName : "twitter"//Replace "twitter" with your twitter username without @

};
export { greeting, socialMediaLinks, skillsSection, educationInfo, techStack, workExperiences, openSource, bigProjects, achievementSection, blogSection, talkSection, podcastSection, contactInfo , twitterDetails};
