import React from "react";
import "./Progress.css";
import { techStack } from "../../portfolio";
import { Fade } from "react-reveal";
import AnimateCount from '../../components/AnimateCount'


export default function StackProgress() {
    if(techStack.viewSkillBars){
        
        return (
            <Fade bottom duration={1000} distance="20px">
                <div className="skills-container">

                    <div className="skills-bar">
                        <h1 className="skills-heading">Proficiency</h1>
                        {
                            techStack.experience.map((exp) => {
                                return (
                                    <AnimateCount stopNum={exp.progressPercentage} stack={exp.Stack}/>
                                )
                            })
                        }
                    </div>

                    <div className="skills-image">
                        <img alt="Skills" src={require("../../assets/images/skill.svg")} />
                    </div>

                </div>
            </Fade>
        );
    }
    return null;
}
