import React from "react";
import "./App.css";
import { chosenTheme } from "./theme";
import Main from "./containers/Main";

function App() {
  return (
    <div>
      <Main theme={chosenTheme}/>
    </div>
  );
}

export default App;
