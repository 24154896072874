import React from 'react'
import { useCountUp } from 'react-countup';

export default function AnimateCount({stopNum, stack}) {

    const { countUp } = useCountUp({ start: 0, end: parseInt(stopNum), delay: 1 });

    return (
        <div className="skill">
            <p>{stack}</p>
            <div className="meter">
                <span style={{width: `${countUp}%`}}></span>
            </div>
        </div>
    );
}
