import React from "react";
import "./AchievementCard.css";

export default function AchievementCard({ cardInfo, isDark }) {
  function openUrlInNewTab(url) {
    var win = window.open(url, "_blank");
    
    win?.focus && win.focus();
  }

  return (
    <div 
      className={isDark ? "dark-mode certificate-card" : "certificate-card"}
      onClick={()=> openUrlInNewTab(cardInfo.url)}
      >

      <div className="certificate-image-div">
        <img src={cardInfo.image} alt="PWA" className="card-image"></img>
      </div>

      <div className="certificate-detail-div">
        <h5 className={isDark ? "dark-mode card-title" : "card-title"}>
          {cardInfo.title}
        </h5>
        <p className={isDark ? "dark-mode card-subtitle" : "card-subtitle"}>
          {cardInfo.description}
        </p>
      </div>

      <div className="certificate-card-footer">
        {cardInfo.footer.map((v, i) => {
          return (
            <span
              class={isDark ? "dark-mode certificate-tag" : "certificate-tag"}
              // onClick={() => openUrlInNewTab(v.url)}
            >
              {v}
            </span>
          );
        })}
      </div>

      <a href="#" onClick={() => openUrlInNewTab(cardInfo.gitUrl)} className={`icon-button ${isDark ? 'github-dark' : 'github-light'}`} target="_blank">
        <i className="fab fa-github"></i>
        <span></span>
      </a>

    </div>
  );
}
